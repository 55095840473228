import React from "react";
import "../styles/Sphere.css";
import { cos, matrix, multiply, sin, sqrt } from "mathjs";

export default class Sphere extends React.Component {
  state = {
    k: [ -1 / Math.sqrt(2), 1 / Math.sqrt(2), 0 ],
    theta: 0.1,
    t: 0,
    initialPoints: this.getPoints(26),
    points: [],
  };

  componentDidMount() {
    this.setState({
      k: this.state.k,
      theta: this.state.theta,
      t: this.state.t,
      initialPoints: this.state.initialPoints,
      points: this.state.initialPoints.slice(),
    });

    setInterval(() => {
      const points = [];
      for (const point of this.state.initialPoints) {
        points.push(this.rotate(point, this.state.t * this.state.theta));
      }
      this.setState({
        k: this.state.k,
        theta: this.state.theta,
        t: this.state.t + 0.3,
        initialPoints: this.state.initialPoints,
        points: points,
      });
    }, 50);
  }

  render() {
    const points = [];
    for (const [ i, point ] of this.state.points.entries()) {
      const scale = 2 / (3 + point[2]);
      points.push(<div className="point" style={ {
        position: "absolute",
        left: point[0] * 125 + 125,
        top: point[1] * 125 + 125,
        width: 10 * scale,
        height: 10 * scale,
        opacity: 1 * scale * scale,
        fontSize: 14,
      } } key={ `point${ i }` }><p>{ "abcdefghijklmnopqrstuvwxyz"[i] }</p></div>);
    }
    return (
      <div className="sphere">
        { points }
      </div>
    );
  }

  getPoints(n) {
    const points = [];
    const phi = Math.PI * (3 - Math.sqrt(5));

    for (let i = 0; i < n; i++) {
      const y = 1 - (i / (n - 1)) * 2;
      const radius = Math.sqrt(1 - y * y)
      const theta = phi * i;
      const x = Math.cos(theta) * radius;
      const z = Math.sin(theta) * radius;
      points.push([ x, y, z ]);
    }

    return points;
  }

  convertTo2D(point) {
    return [ point[0] / (point[2] + 1), point[1] / (point[2] + 1) ];
  }

  rotate(v, t) {
    const n1 = 1 / sqrt(2);
    const n2 = 1 / sqrt(2);
    const n3 = 0;
    const r = matrix(
      [ [ cos(t) + n1 * n1 * (1 - cos(t)), n1 * n2 * (1 - cos(t)) - n3 * sin(t), n1 * n3 * (1 - cos(t)) + n2 * sin(t) ],
        [ n2 * n1 * (1 - cos(t)) + n3 * sin(t), cos(t) + n2 * n2 * (1 - cos(t)), n2 * n3 * (1 - cos(t)) - n1 * sin(t) ],
        [ n3 * n1 * (1 - cos(t)) - n2 * sin(t), n3 * n2 * (1 - cos(t)) + n1 * sin(t), cos(t) + n3 * n3 * (1 - cos(t)) ] ]
    );
    const vec = matrix([ [ v[0] ], [ v[1] ], [ v[2] ] ]);
    const vec_new = multiply(r, vec).toArray();
    return [ vec_new[0][0], vec_new[1][0], vec_new[2][0] ];
  }
}
