import styled from "styled-components";

export const Button = (props) => {

  return (
    <StyledButton { ...props } type={ props.type || "default" }>
      <ButtonText { ...props } type={ props.type || "default" } onClick={ () => {
      } }>{ props.text }</ButtonText>
    </StyledButton>
  );
}

const ButtonText = styled.text`
  display: inline-block;
  position: relative;
  text-align: center;
  font-family: ${ props => props.theme.title_font };
  font-size: ${ props => `${ props.fontSize || 20}px` };
  color: ${ props => props.type == "primary" ? props.theme.primary : props.theme.outline };
  justify-content: center;
  align-items: center;
  text-align-all: center;
  vertical-align: middle;
  transition: 100ms ease-in-out;
  padding: ${ props => `${ props.padding || 0}px` };
`;

const StyledButton = styled.button`
  border: ${ props => props.theme.neu ? "none" : `${ 5 / 20 * (props.fontSize || 20) }px solid ${ props.type == "primary" ? props.theme.primary : props.theme.outline }` };
  background-color: transparent;
  align-content: center;
  justify-content: center;
  padding: 8px 12px 4px 12px;
  border-radius: 10px;
  box-shadow: ${ props => props.theme.neu ? `4px 4px 8px ${ props.theme.neu_theme.dark_shadow }, -4px -4px 8px ${ props.theme.neu_theme.light_shadow1 }` : "none" };
  transition: 100ms ease-in-out;
  
  &:hover {
    cursor: pointer;
    border-color: ${ props => props.neu ? "none" : props.type == "primary" ? props.theme.primary_dark : props.theme.outline_dark };
    box-shadow: ${ props => props.neu ? `inset 2px 2px 8px ${ props.theme.neu_theme.dark_shadow }, inset -2px -2px 8px ${ props.theme.neu_theme.light_shadow2 }` : "none" };
  }

  &:disabled {
    border-color: ${ props => props.type == "primary" ? props.theme.primary_dark : props.theme.outline_dark };
    opacity: 0.4;
  }

  &:hover:disabled {
    cursor: default;
  }

  
  &:not(:disabled) ${ ButtonText }:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${ props => props.type == "primary" ? props.theme.primary_dark : props.theme.outline_dark };
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:not(:disabled) ${ ButtonText }:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
  &:disabled ${ ButtonText } {
    color: ${ props => props.type == "primary" ? props.theme.primary_dark : props.theme.outline_dark };
  }

  &:not(:disabled):hover ${ ButtonText } {
    color: ${ props => props.type == "primary" ? props.theme.primary_dark : props.theme.outline_dark } !important;
    cursor: pointer;
  }
`
