import React, { forwardRef } from "react";
import styled from "styled-components";

export const LanguageBadge = forwardRef((props, ref) => {
  return (
    <LanguageBadgeContainer key={ props.item } onClick={ () => typeof props.onClick == "undefined" ? () => {
    } : props.onClick(props.item) } ref={ ref }>
      <LanguageBadgeIcon
        src={ `https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${ props.icon }` }/>
      <LanguageBadgeText>{ props.text }</LanguageBadgeText>
    </LanguageBadgeContainer>
  );
});

const LanguageBadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  
  &:hover {
    cursor: pointer;
  }
`;

const LanguageBadgeIcon = styled.img`
  width: 64px;
  height: 64px;
`;

const LanguageBadgeText = styled.text`
  font-family: ${ props => props.theme.body_font };
  font-size: 17px;
  color: ${ props => props.theme.text_secondary };
`;