import React, { forwardRef, useImperativeHandle, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { CloseRounded, DehazeRounded, HomeRounded } from "@mui/icons-material";

export const NavBar = forwardRef((props, ref) => {

  const [ extendNavBar, setExtendNavBar ] = useState(false);
  const [ up, setUp ] = useState(false);

  useImperativeHandle(ref, () => ({
    close: () => {
      setUp(true);
      setTimeout(() => {
        setExtendNavBar(false);
        setUp(false);
      }, 300);
    }
  }));

  return (
    <NavBarContainer extendNavBar={ extendNavBar }>
      <NavBarInnerContainer>
        <NavBarHomeIcon to={ "/" }><LeftContainer><HomeRounded fontSize="large"/></LeftContainer></NavBarHomeIcon>
        <RightContainer>
          <NavBarLinkContainer>
            <NavBarLink to="/about/">ABOUT</NavBarLink>
            <NavBarLink to="/projects/">PROJECTS</NavBarLink>
            <NavBarLink to="/experience/">EXPERIENCE</NavBarLink>
            <NavBarLink to="/contact/">CONTACT</NavBarLink>
            <OpenLinksButton
              onClick={ () => {
                setUp(true);
                setTimeout(() => {
                  setExtendNavBar(curr => !curr);
                  setUp(false);
                }, 300);
              } }
            >
              { extendNavBar ? <CloseRounded/> : <DehazeRounded/> }
            </OpenLinksButton>
          </NavBarLinkContainer>
        </RightContainer>
      </NavBarInnerContainer>
      <NavBarExtendedContainer extendNavBar={ extendNavBar }>
        <NavBarLinkExtended up={ up } to="/about/">ABOUT</NavBarLinkExtended>
        <NavBarLinkExtended up={ up } to="/projects/">PROJECTS</NavBarLinkExtended>
        <NavBarLinkExtended up={ up } to="/experience/">EXPERIENCE</NavBarLinkExtended>
        <NavBarLinkExtended up={ up } to="/contact/">CONTACT</NavBarLinkExtended>
      </NavBarExtendedContainer>
    </NavBarContainer>
  );
});

const NavBarHomeIcon = styled(Link)`
  margin-left: 5%;

  &::after {
    background-color: transparent !important;
  }
`;

const NavBarContainer = styled.nav`
  position: fixed;
  width: 100%;
  height: ${ (props) => (props.extendNavBar ? "100vh" : "60px") };
  background-color: ${ props => props.theme.background };
  display: flex;
  flex-direction: column;
  box-shadow: ${ props => `1px -1px 15px 8px ${ props.theme.shadow }` };
  pointer-events: all;
  z-index: 1000;

  @media (min-width: 750px) {
    height: 60px;
  }
`;

const LeftContainer = styled.div`
  //flex: 30%;
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;
`;

const RightContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 50px;
`;

const NavBarInnerContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
`;

const NavBarLinkContainer = styled.div`
  display: flex;
`

const NavBarLink = styled(Link)`
  display: inline-block;
  position: relative;
  color: ${ props => props.theme.primary };
  font-size: x-large;
  font-family: ${ props => props.theme.title_font };
  text-decoration: none;
  margin: 10px 20px;

  @media (max-width: 750px) {
    display: none;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${ props => props.theme.primary_dark };
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover {
    color: ${ props => props.theme.primary_dark };
    cursor: pointer;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

const OpenLinksButton = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  background: none;
  border: none;
  color: white;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-out;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 750px) {
    display: none;
  }
`

const NavBarLinkExtended = styled(Link)`
  display: inline-block;
  position: relative;
  color: ${ props => props.theme.primary };
  font-size: x-large;
  font-family: ${ props => props.theme.title_font };
  text-decoration: none;
  margin: 10px;
  animation: animate-down 0.5s;

  ${ props => props.up && css`
    animation: animate-up 0.5s;
  ` }
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${ props => props.theme.primary_dark };
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover {
    color: ${ props => props.theme.primary_dark };
    cursor: pointer;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  @keyframes animate-down {
    0% {
      margin-top: -50px;
    }
    100% {
      margin-top: 10px;
    }
  }

  @keyframes animate-up {
    0% {
      margin-top: 10px;
      opacity: 1;
    }
    100% {
      margin-top: -40px;
      opacity: 0;
    }
  }

`

const NavBarExtendedContainer = styled.div`
  display: ${ (props) => (props.extendNavBar ? "flex" : "none") };
  flex-direction: column;
  align-items: center;
  padding: 100px 0 0 0;
  transition: 0.5s ease-out;

  @media (min-width: 750px) {
    display: none;
  }
`;