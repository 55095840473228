import React, { useRef, useState } from "react";
import ProjectTile from "../components/ProjectTile";
import { FolderOpenRounded } from "@mui/icons-material";
import styled from "styled-components";
import { FilterBar } from "../components/FilterBar";
import { getTypes, useLocationChange } from "../utils/Utils";
import { Data } from "../data/Data";

export const Projects = (props) => {

  document.title = "My projects";

  useLocationChange(props.navbar);

  const filterRef = useRef(null);

  const [ projects, setProjects ] = useState([ ...Data.ProjectDetails ]);
  const [ selected, setSelected ] = useState([]);

  const onChange = (selected) => {
    setSelected(selected);
    filterRef.current.setSelected(selected);
    if (selected.length === 0) {
      setProjects([ ...Data.ProjectDetails ]);
    } else {
      setProjects([ ...(Data.ProjectDetails.filter(p => selected.every(t => p[4].includes(t)))) ]);
    }
  }

  const filter = (type) => {
    if (!selected.includes(type)) {
      onChange([ type, ...selected ]);
    }
  }

  const tiles = [];

  for (let project of projects) {
    tiles.push(
      <ProjectTile data={ project } filter={ filter } icon={ <FolderOpenRounded fontSize="large"/> }/>
    );
  }

  return (
    <ProjectsContainer height={ window.innerHeight - 220 }>
      <ProjectsTitleContainer>
        <ProjectsTitle>My Projects:</ProjectsTitle>
        <FilterBar ref={ filterRef } types={ getTypes() } onChange={ onChange }/>
      </ProjectsTitleContainer>
      <ProjectsGrid>{ tiles }</ProjectsGrid>
    </ProjectsContainer>
  );
}

const ProjectsContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 80px 0 0 0;
  background-color: ${ props => props.theme.background };
  display: flex;
  flex-direction: column;
  height: ${ props => `max(${ props.height }px, 100%)`};
`

const ProjectsTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const ProjectsTitle = styled.text`
  font-family: ${ props => props.theme.title_font };
  font-size: 54px;
  color: ${ props => props.theme.primary };
  padding: 10px 0 0 0;
  
  @media(max-width: 620px) {
    font-size: 38px;
  }
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: 340px 340px 340px;
  grid-column-gap: 8px;
  grid-row-gap: 28px;
  //padding: 20px -20px 0 0;
  margin: 40px 0 0 0;

  @media (max-width: 1100px) {
    grid-template-columns: 260px 260px;
  }
  
  @media (max-width: 620px) {
    grid-template-columns: 360px;
  }
`