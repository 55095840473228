import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ImageGallery } from "../components/ImageGallery";
import "../styles/a.css";
import { GitHub, OpenInNewRounded } from "@mui/icons-material";
import { LanguageBadge } from "../components/LanguageBadge";
import { useLocationChange } from "../utils/Utils";

const icons = {
  "github": <GitHub fontSize="large"/>,
  "site": <OpenInNewRounded fontSize="large"/>,
};

export const ProjectPage = (props) => {

  document.title = props.data[0];

  useLocationChange(props.navbar);

  const [ selected, setSelected ] = useState(-1);
  const badges = useRef({});
  const badgesContainer = useRef(null);
  const pointer = useRef(null);
  const toolsRef = useRef(null);
  const [ temp, setTemp ] = useState(0);

  const toolsList = JSON.parse(props.data[4]);
  const iconsList = JSON.parse(props.data[5]);

  useLayoutEffect(() => {
    if (temp == 0) {
      setTimeout(() => {
        setTemp(temp + 1);
      }, 100);
    }
  }, [ temp ]);

  const buttons = [];

  for (let [ site, link ] of Object.entries(JSON.parse(props.data[7]))) {
    buttons.push(
      <ProjectPageIconButton onClick={ () => window.open(link, "_blank") }>{ icons[site] }</ProjectPageIconButton>
    )
  }

  const chips = [];
  const tools = [];

  for (let item in toolsList) {
    const copy = item;
    chips.push(
      <ProjectPageChip>
        <ProjectPageChipText>{ toolsList[item] }</ProjectPageChipText>
      </ProjectPageChip>
    );
    tools.push(
      <LanguageBadge item={ item } onClick={ () => {
        setSelected(copy);
        setTimeout(() => {
          setTemp(temp + 1);
        }, 50);
      } } ref={ ref => badges.current[copy] = ref } text={ toolsList[item] } icon={ iconsList[item] }/>
    );
  }

  return (
    <ProjectPageRoot>
      <ProjectPageToolsPurposeHighlight width={ selected > -1 ? badges.current[selected].clientWidth + 20 : 0 }
                                        height={ selected > -1 ? badges.current[selected].clientHeight + 20 : 0 }
                                        top={ selected > -1 ? badges.current[selected].offsetTop - badges.current[selected].clientHeight - 20 : 0 }
                                        offset={ selected > -1 ? badges.current[selected].offsetLeft - 35 + badges.current[selected].clientWidth : 0 }/>
      <ProjectPageTitle>{ props.data[0] }</ProjectPageTitle>
      <ProjectPageSubHeaderContainer>
        <ProjectPageInfoContainer>
          <ProjectPageInfoDate>{ props.data[2] }</ProjectPageInfoDate>
          <ProjectPageChipsContainer>{ chips }</ProjectPageChipsContainer>
        </ProjectPageInfoContainer>
        <ProjectPageLinksBox>{ buttons }</ProjectPageLinksBox>
      </ProjectPageSubHeaderContainer>
      <ProjectPageContentContainer>
        <ImageGallery float={ window.innerWidth > 900 ? "right" : "none" }
                      width={ window.innerWidth > 900 ? 500 : window.innerWidth - 80 }
                      height={ window.innerWidth > 900 ? 400 : (window.innerWidth - 80) * 9 / 16 }
                      imgs={ JSON.parse(props.data[8]).length > 0 ? JSON.parse(props.data[8]) :
                        [ "red", "orange", "yellow", "green", "blue", "purple" ] }/>
        <ProjectPageContentText dangerouslySetInnerHTML={ { __html: props.data[3] } } width={ window.innerWidth }/>
      </ProjectPageContentContainer>
      <div style={ { height: "80px" } }/>
      <ProjectPageToolsContainer ref={ toolsRef }>
        <ProjectPageTitle>TOOLS</ProjectPageTitle>
        <ProjectPageToolsBadgeContainer ref={ badgesContainer }>{ tools }</ProjectPageToolsBadgeContainer>
        <ProjectPageToolsPurposeContainer selected={ selected }>
          <ProjectPageToolsPurposeBar width={ selected > -1 ? badgesContainer.current.clientWidth : 0 }/>
          <ProjectPageToolsPurposePointer
            offset={ selected > -1 ? badges.current[selected].offsetLeft - 40 + badges.current[selected].clientWidth : 0 }/>
          <ProjectPageToolsPurpose>Purpose</ProjectPageToolsPurpose>
          <ProjectPageToolsPurposeText
            ref={ pointer }>{ selected > -1 ? JSON.parse(props.data[6])[selected] : 0 }</ProjectPageToolsPurposeText>
        </ProjectPageToolsPurposeContainer>
      </ProjectPageToolsContainer>
      <div style={ { height: "100px" } }/>
      <ProjectPageBackground offsetTop={ toolsRef.current == null ? 0 : toolsRef.current.offsetTop - 40 }
                             height={ toolsRef.current == null ? 0 : toolsRef.current.clientHeight + 80 }
                             width={ toolsRef.current == null ? 0 : window.innerWidth }/>
    </ProjectPageRoot>

  );
}

const ProjectPageRoot = styled.div`
  padding: 100px 0 0 0   ;
  background-color: ${ props => props.theme.background };
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
`;

const ProjectPageInfoContainer = styled.div`
  flex: 1;
`;

const ProjectPageSubHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 40px 10px 40px;
  @media(max-width: 620px) {
    padding: 0 20px;
  }
`;


const ProjectPageChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 7px;
  width: 400px;
  align-items: center;
  padding: 15px 0 0 0;
  @media(max-width: 620px) {
    width: 300px;
  }
`;

const ProjectPageChip = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  background-color: ${ props => props.theme.on_background };
`;

const ProjectPageChipText = styled.text`
  font-family: ${ props => props.theme.body_font };
  font-size: 14px;
  color: ${ props => props.theme.text_secondary };
  padding: 5px 10px 5px 10px;
  flex: 1;
`;

const ProjectPageInfoDate = styled.text`
  font-family: ${ props => props.theme.body_font };
  font-size: 16px;
  color: ${ props => props.theme.text_secondary };
`;

const ProjectPageContentContainer = styled.div`
  padding: 0 40px;
  align-self: center;
  justify-self: center;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  @media(max-width: 620px) {
    padding: 0 20px;
  }
`;

const ProjectPageContentText = styled.div`
  font-family: ${ props => props.theme.body_font };
  font-size: 20px;
  color: ${ props => props.theme.text_primary };
  line-height: 30px;
  text-align: justify;
  
  @media(max-width: 620px) {
    font-size: 14px;
  }
`;

const ProjectPageTitle = styled.text`
  padding: 0 40px;
  font-family: ${ props => props.theme.title_font };
  font-size: 54px;
  color: ${ props => props.theme.primary };

  @media(max-width: 620px) {
    font-size: 40px;
    padding: 0 20px;
  }
`;

const ProjectPageLinksBox = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-self: flex-start;
  margin: 30px 0 0 0;
`;

const ProjectPageIconButton = styled.button`
  background-color: transparent;
  color: ${ props => props.theme.text_primary };
  border: none;
  transition: color 250ms ease-out;


  &:hover {
    color: ${ props => props.theme.text_secondary };
    cursor: pointer;
  }
`;

const ProjectPageToolsContainer = styled.div`
  margin: 0 40px 0 0;
  z-index: 2;
`;

const ProjectPageToolsBadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin: 40px 0 0 0;
`;

const ProjectPageToolsPurposeContainer = styled.div`
  display: ${ props => props.selected > -1 ? "flex" : "none" };
  flex-direction: column;
  gap: 20px;
  margin: 10px 0 0 0;
`;

const ProjectPageToolsPurposeText = styled.text`
  font-family: ${ props => props.theme.body_font };
  font-size: 20px;
  color: ${ props => props.theme.text_primary };
  align-self: center;
  justify-self: center;
  position: relative;
`;

const ProjectPageToolsPurposePointer = styled.div`
  border-style: solid;
  border-top-width: 0;
  border-right-width: 7px;
  border-bottom-width: 14px;
  border-left-width: 7px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: ${ props => props.theme.primary };
  border-left-color: transparent;
  width: 0;
  height: 0;
  margin-left: ${ props => `${ props.offset }px` };
  margin-top: -34px;
  transition: 250ms ease-in-out;
  position: relative;
`;

const ProjectPageToolsPurposeBar = styled.div`
  width: ${ props => `${ props.width }px` };
  height: 4px;
  background-color: ${ props => props.theme.primary };
  position: relative;
  margin-top: 24px;
  border-radius: 2px;
`;

const ProjectPageToolsPurposeHighlight = styled.div`
  background-color: white;
  width: ${ props => `${ props.width }px` };
  height: ${ props => `${ props.height }px` };
  margin-top: ${ props => `${ props.top }px` };
  margin-left: ${ props => `${ props.offset - 40 }px` };
  border-radius: 10px;
  opacity: 0.1;
  position: absolute;
  transition: 250ms ease-in-out;
  z-index: 2;
`;


const ProjectPageToolsPurpose = styled.text`
  font-family: ${ props => props.theme.body_text };
  font-size: 22px;
  color: ${ props => props.theme.primary };
  align-self: center;
  text-decoration: underline;
`;

const ProjectPageBackground = styled.div`
  position: absolute;
  transform: rotate(2deg);
  background-color: ${ props => props.theme.background_secondary };
  top: ${ props => `${ props.offsetTop }px` };
  height: ${ props => `calc(${ props.height }px * 0.99619469809)` };
  width: ${ props => `calc(${ props.width }px / 0.99619469809 + ${ props.height }px * 0.08715574274)` };
  left: ${ props => `calc(-${ props.height }px * 0.08715574274 / 2 - 2.4px)` };
`;