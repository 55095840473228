import React, { forwardRef, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import { Dropdown } from "./Dropdown";
import { CloseRounded } from "@mui/icons-material";

export const FilterBar = forwardRef((props, ref) => {

  const [ types, setTypes ] = useState(props.types);
  const [ selected, setSelected ] = useState([]);

  useImperativeHandle(ref, () => ({
    getSelected: () => {
      return selected;
    },
    setTypes: (types) => {
      setTypes(types);
    },
    setSelected: (selected) => {
      setSelected(selected);
    }
  }));

  const onChange = (prev, curr) => {
    if (selected.includes(curr)) {
      const index = selected.indexOf(curr);
      if (index > -1) {
        const old = Array.from(selected);
        old.splice(index, 1);
        setSelected([ ...old ]);
        props.onChange(old);
      }
    } else {
      const old = Array.from(selected);
      setSelected([ curr, ...old ]);
      props.onChange([ curr, ...old ]);
    }
  };

  const chips = [];

  for (let item of selected) {
    chips.push(
      <FilterBarChip>
        <FilterBarChipText>{ item }</FilterBarChipText>
        <FilterBarChipIcon onClick={ () => onChange("", item) }><CloseRounded fontSize="x-small"/></FilterBarChipIcon>
      </FilterBarChip>
    );
  }

  return (
    <FilterBarContainer>
      <FilterBarChipsContainer>{ chips }</FilterBarChipsContainer>
      <Dropdown items={ types } placeholder="Filter" onChange={ onChange }/>
    </FilterBarContainer>
  );
});

const FilterBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const FilterBarChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 7px;
  width: 300px;
  align-items: center;
  justify-content: center;
`;

const FilterBarChip = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  background-color: ${ props => props.theme.on_background };
`;

const FilterBarChipText = styled.text`
  font-family: ${ props => props.theme.body_font };
  font-size: 14px;
  color: ${ props => props.theme.text_primary };
  padding: 5px 10px 5px 10px;
  flex: 1;
`;

const FilterBarChipIcon = styled.div`
  display: flex;
  background: none;
  border: none;
  color: ${ props => props.theme.text_primary };
  justify-content: center;
  align-items: center;
  padding: 0 5px 0 0;
  
  &:hover {
    color: ${ props => props.theme.text_secondary };
    cursor: pointer;
  }
`;
