import styled from "styled-components";
import { getTextHeight, getTextWidth } from "../utils/Utils";

export const DividerText = (props) => {
  return (
    <DividerTextContainer { ...props }>
      <DividerTextLine { ...props } margin={ props.vertical ? "0 0 10px 0" : "0 10px 0 0" }/>
      <DividerTextText { ...props }>{ props.text }</DividerTextText>
      <DividerTextLine { ...props } margin={ props.vertical ? "10px 0 0 0" : "0 0 0 10px" }/>
    </DividerTextContainer>
  );
}

const DividerTextContainer = styled.div`
  width: ${ props => props.vertical ? `${ getTextWidth(props.text, props.font) }px` : `${ props.length }px` };
  height: ${ props => props.vertical ? `${ props.length }px` : `${ getTextHeight(props.text, props.font) }px` };
  display: flex;
  flex-direction: ${ props => props.vertical ? "column" : "row" };
  align-items: center;
  justify-content: center;
  margin: ${ props => props.vertical ? "0 22px 0 22px" : "32px 0 32px 0" };
  z-index: 2;
`;

const DividerTextLine = styled.div`
  width: ${ props => props.vertical ? "4px" : `${ (props.length - getTextWidth(props.text, props.font) - 20) / 2 }px` };
  height: ${ props => props.vertical ? `${ (props.length - getTextHeight(props.text, props.font) - 20) / 2 }px` : "4px" };
  background-color: ${ props => props.theme.neu ? props.theme.background : props.theme.outline };
  margin: ${ props => props.margin };
  border-radius: 2px;
  box-shadow: ${ props => props.theme.neu ? `1px 1px 2px ${ props.theme.neu_theme.dark_shadow }, -1px -1px 2px ${ props.theme.neu_theme.light_shadow3 }` : "none" };
`;

const DividerTextText = styled.text`
  font-family: ${ props => props.theme.title_font };
  font-size: 24px;
  color: ${ props => props.theme.neu ? props.theme.background : props.theme.outline };
  text-shadow: ${ props => props.theme.neu ? `2px 2px 4px ${ props.theme.neu_theme.dark_shadow }, -2px -2px 4px ${ props.theme.neu_theme.light_shadow3 }` : "none" };
`;
