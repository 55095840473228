import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { asyncTimeout } from "../utils/Utils";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";

export const ImageGallery = (props) => {

  const [ intervalID, setIntervalID ] = useState(null);

  const [ focus, setFocus ] = useState(0);
  const [ startX, setStartX ] = useState(0);
  const [ deltaX, setDeltaX ] = useState(0);
  const [ touching, setTouching ] = useState(false);
  const [ imgs, setImgs ] = useState([ ...props.imgs ]);
  const [ transition, setTransition ] = useState(0);

  const imgComponents = [];
  const bullets = [];

  const changeFocus = useCallback((dir) => {
    if (dir == 0) { // right
      setTransition(200);
      setDeltaX(-props.width * 2);
      setTimeout(() => {
        const first = imgs.shift();
        imgs.push(first);
        setImgs([ ...imgs ]);
      }, 200);
    } else if (dir == 1) { // left
      setTransition(200);
      setDeltaX(props.width * 2);
      setTimeout(() => {
        const first = imgs.pop();
        imgs.unshift(first);
        setImgs([ ...imgs ]);
      }, 200);
    }
  }, [ imgs, props.width ]);

  const stopInterval = () => {
    clearInterval(intervalID);
  }

  useEffect(() => {
    if (intervalID == null) {
      const interval = setInterval(() => {
        changeFocus(0);
      }, 3000);
      setIntervalID(interval);
    }

    setDeltaX(0);
    setFocus(props.imgs.indexOf(imgs[0]));
    setTransition(0);
  }, [ changeFocus, imgs, props.imgs, intervalID ]);

  const start = (x) => {
    setStartX(x);
    setTouching(true);
  }

  const move = (x) => {
    if (touching) {
      setDeltaX((x - startX) * (props.imgs.includes("red") && props.imgs.includes("orange") &&
        props.imgs.includes("yellow") && props.imgs.includes("green") && props.imgs.includes("blue") &&
        props.imgs.includes("purple") ? 1 : -1));
    }
  }

  const end = () => {
    stopInterval();
    if (deltaX > 100) {
      changeFocus(1);
    } else if (deltaX < -100) {
      changeFocus(0);
    }
    setTouching(false);
    setStartX(0);
  }

  const startT = (e) => {
    start(e.targetTouches[0].clientX);
  }

  const moveT = (e) => {
    move(e.targetTouches[0].clientX);
  }

  const endT = () => {
    end();
  }

  const startM = (e) => {
    start(e.clientX);
  }

  const moveM = (e) => {
    move(e.clientX);
  }

  const endM = (e) => {
    end();
  }

  for (let img in imgs) {
    let offset = 0;

    if (img == 0) {
      offset = deltaX;
    }

    imgComponents.push(
      [ "red", "orange", "yellow", "green", "blue", "purple" ].includes(imgs[img]) ?
        <ImageGalleryPlaceHolder transition={ transition } offset={ offset } color={ imgs[img] }
                                 z={ deltaX > 0 ? (img == 0 ? imgs.length : img) : (imgs.length - 1 - img) }
                                 width={ props.width } height={ props.height } key={ img }/> :
        <ImageGalleryImageContainer transition={ transition } offset={ offset } width={ props.width }
                                    z={ deltaX > 0 ? (img == 0 ? imgs.length : img) : (imgs.length - 1 - img) }
                                    height={ props.height } key={ img }>
          <ImageGalleryImage alt="img" src={ imgs[img] } draggable={ false } width={ props.width }
                             height={ props.height }/>
        </ImageGalleryImageContainer>
    );
  }

  for (let img in props.imgs) {
    bullets.push(
      <ImageGalleryBullet onClick={ async () => {
        for (let i = 0; i < Math.abs(img - focus); i++) {
          change(img > focus ? 0 : 1);
          await asyncTimeout(300);
        }
      } } selected={ img == focus }/>
    );
  }

  const change = (num) => {
    changeFocus(num);
    stopInterval();
  }

  return (
    <ImageGalleryContainer width={ props.width } height={ props.height } float={ props.float }>
      <ImageGalleryButtonLeft onClick={ () => change(1) } width={ props.width } height={ props.height }>
        <ChevronLeftRounded fontSize="large"/>
      </ImageGalleryButtonLeft>
      <ImageGalleryButtonRight onClick={ () => change(0) } width={ props.width } height={ props.height }>
        <ChevronRightRounded fontSize="large"/>
      </ImageGalleryButtonRight>
      <ImageGalleryImagesContainer onTouchStart={ startT } onTouchMove={ moveT } onTouchEnd={ endT }
                                   onMouseDown={ startM } onMouseMove={ moveM } onMouseUp={ endM } onMouseLeave={ endM }
                                   width={ props.width }
                                   height={ props.height }>{ imgComponents }</ImageGalleryImagesContainer>
      <ImageGalleryBulletsContainer width={ props.width }
                                    height={ props.height }>{ bullets }</ImageGalleryBulletsContainer>
    </ImageGalleryContainer>
  );
};

const ImageGalleryContainer = styled.div`
  width: ${ props => `${ props.width }px` };
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  float: ${ props => props.float };
  padding: ${ props => props.float === "right" ? "0 0 0 40px" : "0" };
`;

const ImageGalleryImagesContainer = styled.div`
  display: flex;
  width: ${ props => `${ props.width }px` };
  height: ${ props => `${ props.height }px` };
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

const ImageGalleryPlaceHolder = styled.div.attrs(props => ({
  style: {
    marginLeft: `${ props.offset }px`,
    transition: `margin-left ${ props.transition }ms ease-out`,
  }
}))`
  width: ${ props => `${ props.width }px` };
  height: ${ props => `${ props.height }px` };
  position: absolute;
  z-index: ${ props => props.z };
  background-color: ${ props => props.color };
`;

const ImageGalleryImageContainer = styled.div.attrs(props => ({
  style: {
    marginLeft: `${ -props.offset }px`,
    transition: `margin-left ${ props.transition }ms ease-out`,
  }
}))`
  width: ${ props => `${ props.width }px` };
  height: ${ props => `${ props.height }px` };
  position: absolute;
  z-index: ${ props => props.z };
`;

const ImageGalleryImage = styled.img`
  position: absolute;
  object-fit: contain;
`;

const ImageGalleryBulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

const ImageGalleryBullet = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${ props => props.selected ? props.theme.primary : props.theme.outline };
  transition: 100ms linear;
`;

const ImageGalleryButtonLeft = styled.div`
  position: absolute;
  z-index: 50;
  margin-right: ${ props => `${ props.width - 40 }px` };
  color: ${ props => props.theme.outline };
  opacity: 0.2;
  transition: 100ms ease-in-out;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const ImageGalleryButtonRight = styled.div`
  position: absolute;
  z-index: 50;
  margin-left: ${ props => `${ props.width - 40 }px` };
  color: ${ props => props.theme.outline };
  opacity: 0.2;
  transition: 100ms ease-in-out;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;