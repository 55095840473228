export const THEME = {
  main: {
    neu: false,
    title_font: "AppleTea",
    body_font: "Electrolux",
    background: "#303030",
    background_secondary: "#202020",
    on_background: "#424242",
    text_primary: "#b5b5ab",
    text_secondary: "#97978c",
    text_disabled: "#828278",
    text_hint: "#626066",
    secondary_text_primary: "#9ba6b3",
    secondary_text_secondary: "#8a919b",
    primary: "#E2DBAD",
    primary_light: "rgb(231, 226, 199)",
    primary_dark: "rgb(158, 153, 121)",
    primary_text: "rgba(0, 0, 0, 0.87)",
    secondary: "#cfe4ff",
    secondary_light: "rgb(216, 233, 255)",
    secondary_dark: "rgb(144, 159, 178)",
    secondary_text: "rgba(0, 0, 0, 0.87)",
    error: "#c82b24",
    error_light: "rgb(211, 85, 79)",
    error_dark: "rgb(140, 30, 25)",
    error_text: "#FFFFFF",
    warning: "#FF9800",
    warning_light: "#FFB74D",
    warning_dark: "#F57C00",
    warning_text: "rgba(0, 0, 0, 0.87)",
    info: "#2196F3",
    info_light: "#64B5F6",
    info_dark: "#1976D2",
    info_text: "#ffffff",
    success: "#4CAF50",
    success_light: "#81C784",
    success_dark: "#388E3C",
    success_text: "rgba(0, 0, 0, 0.87)",
    divider: "rgba(255, 255, 255, 0.12)",
    outline: "#938F99",
    outline_dark: "#66636a",
    shadow: "#202020",
  },
  main_neu: {
    neu: true,
    title_font: "AppleTea",
    body_font: "Electrolux",
    background: "#072b4c",
    focus: "#fff9da",
    body: "#b2bdde",
    body_focus: "#cfe4ff",
    body_unfocus: "#8892b3",
    on_background1: "#064663",
    neu_theme: {
      dark_shadow: "black",
      light_shadow1: "#b7b399",
      light_shadow2: "#0c406f",
      light_shadow3: "#1b5c97",
      light_shadow4: "#093964",
    }

  },
  light: {},
  light_neu: {}
}

const main = {
  background: "#303030",
  dark: "#041C32",
  focus: "#fff9da",
  body: "#b2bdde",
  secondary: "#a1bdce",
  body_focus: "#cfe4ff",
  secondary_focus: "#bedbec",
  body_unfocus: "#8892b3",
  on_background1: "#064663",
  on_background2: "#106084",
  on_background3: "#0a5578",
  hover1: "#8599b2",
  hover_focus: "#b7b399",
  shadow: "#021117",
  unfocus: "#938F99",
  error: "#c82b24",
}
