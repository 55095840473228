import React, { useRef, useState } from "react";
import styled from "styled-components";
import { TextField } from "../components/TextField";
import { TextArea } from "../components/TextArea";
import { DividerText } from "../components/DividerText";
import { Button } from "../components/Button";
import { useLocationChange } from "../utils/Utils";
import { useCookies } from "react-cookie";

const emoji = require("node-emoji");

export const Contact = (props) => {

  document.title = "Contact me"

  useLocationChange(props.navbar);

  const [ cookies, setCookie, removeCookie ] = useCookies([ "sent" ]);
  const [ disabled, setDisabled ] = useState(false);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const msgRef = useRef(null);
  const alertsRef = props.alertsRef;


  const width = window.innerWidth;
  const offset = 40;


  const submit = async () => {

    setDisabled(true);

    if (emailRef.current.value === "") {
      alertsRef.current.AddAlert({
        title: "Error",
        text: "You need to input your email!",
        type: 1,
      });
    } else if (nameRef.current.value === "") {
      alertsRef.current.AddAlert({
        title: "Error",
        text: "You need to input your name!",
        type: 1,
      });
    } else if (msgRef.current.value === "") {
      alertsRef.current.AddAlert({
        title: "Error",
        text: "You need to input your message!",
        type: 1,
      });
    } else if (!emailRef.current.validity.valid) {
      alertsRef.current.AddAlert({
        title: "Error",
        text: "Invalid email address!",
        type: 1,
      });
    } else if ("sent" in cookies && Date.now() - parseInt(cookies["sent"]) < 120000) {
      alertsRef.current.AddAlert({
        title: "Warning",
        text: "To prevent spam, you can only send a message every 2 minutes!",
        type: 2,
      });
    } else {
      try {
        const res = await fetch(`https://mailer.josherlo.com/send?name=${ encodeURIComponent(nameRef.current.value) }&email=${ encodeURIComponent(emailRef.current.value) }&msg=${ encodeURIComponent(msgRef.current.value) }`, { method: "GET" });
        alertsRef.current.AddAlert({
          title: "Success",
          text: "Successfully sent message!",
          type: 0,
        });
        setCookie("sent", Date.now().toString(), { path: "/", sameSite: "none", secure: true });
      } catch (e) {
        alertsRef.current.AddAlert({
          title: "Error",
          text: "Error sending message!",
          type: 1,
        });
      }
    }


    setDisabled(false);
  }

  const openEmail = () => {
    window.location = "mailto:contact@josherlo.com"
  }

  return (
    <ContactRoot height={ window.innerHeight - 120 }>
      <ContactTitle>{ emoji.emojify("CONTACT ME :wave:") }</ContactTitle>
      <ContactContainer>
        <ContactForm>
          <ContactGrid width={ width } offset={ offset }>
            <TextField id="email" width={ width > 700 ? 340 : width - offset } label={ emoji.emojify("EMAIL :email:") }
                       type="email" ref={ emailRef }/>
            <TextField id="name" width={ width > 700 ? 340 : width - offset }
                       label={ emoji.emojify("NAME :upside_down_face:") }
                       type="text" ref={ nameRef }/>
            <TextArea id="message" label={ emoji.emojify("MESSAGE :pencil:") }
                      width={ width > 700 ? 668 : width - offset - 20 } height={ 250 } colStart={ 1 }
                      colEnd={ width > 700 ? 2 : 1 } ref={ msgRef }/>
          </ContactGrid>
          <Button text="SEND" onClick={ submit } disabled={ disabled }/>
        </ContactForm>
        <DividerText text="OR" vertical={ width > 1000 } length={ width > 1000 ? 320 : width - 60 }/>
        <ContactEmailContainer>
          <ContactTitleText>CONTACT ME AT</ContactTitleText>
          <ContactEmailText>contact@josherlo.com</ContactEmailText>
          <Button text="EMAIL" onClick={ openEmail } type="primary"/>
        </ContactEmailContainer>
      </ContactContainer>
    </ContactRoot>
  );
}

const ContactRoot = styled.div`
  padding: 80px 0 40px 0;
  background-color: ${ props => props.theme.background };
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: ${ props => `max(${ props.height }px, 100%)`};
`;

const ContactTitle = styled.text`
  font-family: ${ props => props.theme.title_font };
  font-size: 54px;
  color: ${ props => props.theme.primary };
  align-self: flex-start;
  padding: 0 40px;
  
  @media(max-width: 620px) {
    padding: 0 20px;
    font-size: 38px;
  }
`;

const ContactContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;

  @media (max-width: 1000px) {
    flex-direction: column;
    height: auto;
  }

`;

const ContactForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

const ContactEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  //padding: 0 20px;
`;

const ContactTitleText = styled.text`
  font-family: ${ props => props.theme.title_font };
  font-size: 32px;
  color: ${ props => props.theme.primary };
`;

const ContactEmailText = styled.text`
  font-family: ${ props => props.theme.body_font };
  font-size: 26px;
  color: ${ props => props.theme.primary };
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: ${ props => props.width > 700 ? "340px 340px" : `${ props.width - props.offset }px` };
  grid-column-gap: 8px;
  grid-row-gap: 10px;
`