import React, { useEffect, useRef, useState } from 'react';
import '../styles/Home.css'
import styled from "styled-components";
import { useLocationChange } from "../utils/Utils";
import { ExpandableListTile } from "../components/ExpandableListTile";
import ProjectTile from "../components/ProjectTile";
import { FolderOpenRounded } from "@mui/icons-material";
import { Button } from "../components/Button";
import { Link } from "react-router-dom";
import { Data } from "../data/Data";


export const Home = (props) => {

  useLocationChange(props.navbar);

  const achievements = [];
  const achievementsRef = useRef({});
  const featured = useRef(null);
  const about = useRef(null);
  const code = useRef(null);
  const [ temp, setTemp ] = useState(0);

  const closeAllExcept = (i) => {
    for (let tile in achievementsRef.current) {
      if (tile != i) {
        achievementsRef.current[tile].close();
      }
    }
  };

  useEffect(() => {
    if (temp == 0) {
      setTimeout(() => {
        setTemp(temp + 1);
      }, 100);
    }
  }, [ temp ]);

  for (let achievement of Data.FeaturedAchievements) {
    const copy = achievement;
    achievements.push(
      <ExpandableListTile onClick={ () => closeAllExcept(copy) }
                          width={ window.innerWidth <= 620 ? window.innerWidth - 40 : window.innerWidth - 120 }
                          key={ copy }
                          data={ Data.Achievements.filter(e => e[0] == achievement)[0] }
                          ref={ ref => achievementsRef.current[copy] = ref }
                          yearWidth={ window.innerWidth <= 620 ? 40 : 55 }/>
    );
  }

  const tiles = [];

  for (let project of Data.FeaturedProjects) {
    tiles.push(
      <ProjectTile data={ Data.ProjectDetails.filter(e => e[0] == project)[0] } filter={ () => {
      } } icon={ <FolderOpenRounded fontSize="large"/> } home={ true }/>
    );
  }

  return (
    <HomeContainer>
      <HomeContentContainer margin={ "80px 0 0 0" }>
        <HomeTextHi>Hi, I'm</HomeTextHi>
        <HomeTextName>Josher</HomeTextName>
        <HomeText ref={ code }>I code.</HomeText>
        <HomeText left={ true }>I am a high school student in Singapore. I enjoy programming and sciences.</HomeText>
      </HomeContentContainer>
      <HomeContentContainer ref={ about } margin={ "80px 0 0 0" }>
        <HomeText>Find out more about me here:</HomeText>
        <HomeLink to="/about">
          <Button padding={ 2 } fontSize={ window.innerWidth <= 620 ? 20 : 26 } text="ABOUT" onClick={ () => {
          } } type="primary"/>
        </HomeLink>
      </HomeContentContainer>
      <HomeContentContainer margin={ "80px 0 60px 0" }>
        <HomeText>Contact me here:</HomeText>
        <HomeLink to="/contact">
          <Button padding={ 2 } fontSize={ window.innerWidth <= 620 ? 20 : 26 } text="CONTACT" onClick={ () => {
          } } type="primary"/>
        </HomeLink>
      </HomeContentContainer>
      <HomeTextTitle>FEATURED PROJECTS</HomeTextTitle>
      <HomeFeaturedProjects ref={ featured }>{ tiles }</HomeFeaturedProjects>
      <HomeContentContainer margin={ "20px 0 40px 0" }>
        <HomeLink to="/projects">
          <Button padding={ 2 } fontSize={ window.innerWidth <= 620 ? 20 : 26 } text="PROJECTS" onClick={ () => {
          } } type="primary"/>
        </HomeLink>
      </HomeContentContainer>
      <HomeTextTitle>FEATURE ACHIEVEMENTS</HomeTextTitle>
      <HomeFeaturedAchievements>{ achievements }</HomeFeaturedAchievements>
      <HomeContentContainer margin={ "0 0 40px 0" }>
        <HomeLink to="/experience">
          <Button padding={ 2 } fontSize={ window.innerWidth <= 620 ? 20 : 26 } text="EXPERIENCES" onClick={ () => {
          } } type="primary"/>
        </HomeLink>
      </HomeContentContainer>
      <HomeBackground offsetTop={ featured.current == null ? 0 : featured.current.offsetTop - 40 }
                      height={ featured.current == null ? 0 : featured.current.clientHeight + 80 }
                      width={ featured.current == null ? 0 : window.innerWidth } type={ false }/>
      <HomeBackground offsetTop={ about.current == null ? 0 : about.current.offsetTop - 40 }
                      height={ about.current == null ? 0 : about.current.clientHeight + 80 }
                      width={ about.current == null ? 0 : window.innerWidth } type={ true }/>
      <HomeBackground offsetTop={ code.current == null ? 0 : code.current.offsetTop - 20 }
                      height={ code.current == null ? 0 : code.current.clientHeight + 40 }
                      width={ code.current == null ? 0 : window.innerWidth } type={ false }/>
    </HomeContainer>
  );
}


const HomeContainer = styled.div`
  background-color: ${ props => props.theme.background };
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HomeContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${ props => props.margin };
  z-index: 2;
`;

const HomeText = styled.text`
  color: ${ props => props.theme.primary };
  font-size: ${ props => props.left ? "40px" : "60px" };
  font-family: Electrolux;
  align-self: ${ props => props.left ? "flex-start" : "center" };
  margin: ${ props => props.left ? "40px 80px" : "20px 0" };
  text-align: center;

  @media (max-width: 700px) {
    font-size: 45px;
    font-size: ${ props => props.left ? "30px" : "45px" };
  }

  @media (max-width: 525px) {
    font-size: 30px;
    font-size: ${ props => props.left ? "20px" : "30px" };
  }
`;

const HomeTextHi = styled.text`
  color: ${ props => props.theme.primary };
  font-size: 60px;
  font-family: AppleTea;
  margin: 40px 0 0 0;

  @media (max-width: 700px) {
    font-size: 45px;
  }

  @media (max-width: 525px) {
    font-size: 30px;
  }
`;

const HomeTextName = styled.div`
  color: ${ props => props.theme.primary };
  font-size: 200px;
  font-family: Neonneon;

  @media (max-width: 700px) {
    font-size: 150px;
  }

  @media (max-width: 520px) {
    font-size: 100px;
  }

`;

const HomeBackground = styled.div`
  position: absolute;
  transform: ${ props => props.type ? "rotate(-2deg)" : "rotate(2deg)" };
  background-color: ${ props => props.theme.background_secondary };
  top: ${ props => `${ props.offsetTop }px` };
  height: ${ props => `calc(${ props.height }px * 0.99619469809)` };
  width: ${ props => `calc(${ props.width }px / 0.99619469809 + ${ props.height }px * 0.08715574274)` };
  left: ${ props => `calc(-${ props.height }px * 0.08715574274 / 2 - 2.4px)` };
  z-index: 0;
`;

const HomeFeaturedProjects = styled.div`
  display: grid;
  grid-template-columns: 340px 340px 340px;
  grid-column-gap: 8px;
  grid-row-gap: 28px;
  z-index: 2;
  margin: 80px 0 60px 0;

  @media (max-width: 1100px) {
    grid-template-columns: 260px 260px;
  }

  @media (max-width: 620px) {
    grid-template-columns: 360px;
  }

`;

const HomeTextTitle = styled.text`
  font-family: ${ props => props.theme.title_font };
  font-size: 44px;
  color: ${ props => props.theme.primary };
  z-index: 2;
  padding: 20px 0 0 0;

  @media (max-width: 620px) {
    font-size: 30px;
  }
`;

const HomeFeaturedAchievements = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - 120px);
  margin: 40px 0 60px 0;
  z-index: 2;
`;

const HomeLink = styled(Link)`
  &::after {
    background-color: transparent !important;
  }
`;