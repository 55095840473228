import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

export const TextField = React.forwardRef((props, ref) => {

  const [ empty, setEmpty ] = useState(true);

  useEffect(() => {
  }, [ empty ]);

  const onChange = (e) => {
    setEmpty(e.target.value.length === 0);
  }

  const onClick = () => {
    ref.current.focus();
  }

  return (
    <TextFieldContainer width={ props.width || 300 } { ...props }>
      <TextFieldInput onChange={ onChange } width={ props.width || 300 } ref={ ref } { ...props }/>
      <TextFieldLabel empty={ empty } onClick={ onClick }>{ props.label }</TextFieldLabel>
      <TextFieldErrorIcon viewBox="0 0 20 20" width={ props.width || 300 }>
        <path fill="#c82b24"
              d="m10.638 1.25c1.25 0 1.9.625 1.875 1.85-.025 1.9-.025 3.15-.65 7.525-.125.875-.375 1.25-1.25 1.25 0 0-1.25 0-1.25 0-.875 0-1.125-.375-1.25-1.25-.625-4.25-.625-5.625-.625-7.475 0-1.275.625-1.9 1.875-1.9m.625 12.5c.675 0 1.3.275 1.775.725 .45.475 .725 1.1.725 1.775 0 1.375-1.125 2.5-2.5 2.5-1.375 0-2.5-1.125-2.5-2.5 0-.675.275-1.3.725-1.775.475-.45 1.1-.725 1.775-.725 0 0 0 0 0 0"/>
      </TextFieldErrorIcon>
    </TextFieldContainer>
  );
});

const TextFieldContainer = styled.div`
  align-items: center;
  display: flex;
  width: ${ (props) => `${ props.width - 30 }px` || "190px" };
  height: 40px;
  border-radius: 10px;
  border: none;
  margin: 10px;
  grid-row-start: ${ props => props.rowStart || 0 };
  grid-row-end: ${ props => props.rowEnd || 0 };
  grid-column-start: ${ props => props.colStart || 0 };
  grid-column-end: ${ props => props.colEnd || 0 };
`;

const TextFieldInput = styled.input`
  height: 40px;
  font-size: 16px;
  font-family: ${ props => props.theme.body_font };
  width: ${ (props) => `${ props.width - 10 }px` };
  outline: ${ props => props.neu ? "none" : `2px solid ${ props.theme.outline }` };
  border: none;
  box-shadow: ${ props => props.theme.neu ? `4px 4px 8px ${ props.theme.neu_theme.dark_shadow }, -4px -4px 8px ${ props.theme.neu_theme.light_shadow4 }` : "none" };
  background-color: transparent;
  border-radius: 10px;
  transition: color 50ms ease-out;
  padding: 0 20px 0 10px;
  color: ${ props => props.theme.outline };

  &:focus {
    outline: ${ props => props.neu ? "none" : `2px solid ${ props.theme.primary }` };
    transition: color 50ms ease-out;
    box-shadow: ${ props => props.theme.neu ? `2px 2px 8px ${ props.theme.neu_theme.dark_shadow }, -2px -2px 8px ${ props.theme.neu_theme.light_shadow2 }` : "none" };
    color: ${ props => props.theme.text_primary };
  }

  &:focus:invalid, &:invalid {
    outline: ${ props => props.neu ? "none" : `2px solid ${ props.theme.error }` };
    transition: color 50ms ease-out;
    color: ${ props => props.theme.error };
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: ${ props => `0 0 0 30px ${ props.theme.background } inset` } !important;
    -webkit-text-fill-color: ${ props => props.theme.outline } !important;
    caret-color: ${ props => props.theme.outline } !important;
  }

  &:focus:-webkit-autofill,
  &:focus:-webkit-autofill:hover,
  &:focus:-webkit-autofill:focus,
  &:focus:-webkit-autofill:active {
    -webkit-box-shadow: ${ props => `0 0 0 30px ${ props.theme.background } inset` } !important;
    -webkit-text-fill-color: ${ props => props.theme.text_primary } !important;
    caret-color: ${ props => props.theme.text_primary } !important;
  }

`;

const TextFieldLabel = styled.text`
  position: absolute;
  font-size: 14px;
  font-family:  ${ props => props.theme.title_font } ;
  margin: -41px 0 0 14px;
  transition: color 50ms ease-out, margin 150ms ease-out, font-size 150ms ease-out, background-color 100ms ease-out;
  background-color: ${ props => props.theme.background } ;
  padding: 0 5px 0 5px;
  color: ${ props => props.theme.outline };
  z-index: 1;

  ${ props => props.empty && css`
    font-size: 16px;
    margin: 4px 0 0 10px;
    background-color: transparent;
    color: ${ props => props.theme.text_hint };
  ` };
  
  ${ TextFieldInput }:focus ~ & {
    font-size: 14px;
    margin: ${ props => props.theme.neu ? "-70px 0 0 6px" : "-41px 0 0 14px" };
    color: ${ props => props.theme.primary };
    background-color: ${ props => props.theme.background };
    transition: color 50ms ease-out, margin 150ms ease-out, font-size 150ms ease-out, background-color 100ms ease-out;
  }

  ${ TextFieldInput }:invalid ~ & {
    color: ${ props => props.theme.error };
    transition: color 50ms ease-out;
  }
  
`

const TextFieldErrorIcon = styled.svg`
  position: absolute;
  width: 17px;
  height: 17px;
  margin-left: ${ props => `${ props.width - 50 }px` };
  
  ${ TextFieldInput }:valid ~ & {
    display: none;
  }
`;