import React from 'react';
import styled from "styled-components";
import Sphere from "../components/Sphere";

export const NotFound = (props) => {

  document.title = "404: Not Found"

  return (
    <NotFoundContainer>
      <NotFound404>
        <NotFound4>4</NotFound4>
        <Sphere/>
        <NotFound4>4</NotFound4>
      </NotFound404>
      <NotFoundError>Not found!</NotFoundError>
      <NotFoundText>In the meantime, look at this cool thing I made :o</NotFoundText>
    </NotFoundContainer>
  );
}

const NotFoundContainer = styled.div`
  padding: 80px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const NotFound404 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const NotFound4 = styled.text`
  position: relative;
  font-size: 260px;
  font-family: Electrolux;
`;

const NotFoundError = styled.text`
  font-family: Electrolux;
  font-size: 32px;
`;

const NotFoundText = styled.text`
  font-family: Electrolux;
`;