import React, { forwardRef, useImperativeHandle, useState } from "react";
import styled from "styled-components";

export const Dropdown = forwardRef((props, ref) => {

  const [ items, setItems ] = useState([ ...props.items ]);
  const [ selectedItem, setSelectedItem ] = useState(props.items[0]);

  const changeSelected = (item) => {
    const prev = selectedItem;
    setSelectedItem(item);
    props.onChange(prev, item);
  }

  useImperativeHandle(ref, () => ({
    setItems: (items) => {
      setItems([ ...items ]);
    },
    getSelectedItem: () => {
      return selectedItem;
    },
    setSelectedItem: (item) => {
      setSelectedItem(item);
    }
  }));

  const itemComponents = [];

  for (let item of items) {
    itemComponents.push(
      <DropdownItem onClick={ () => changeSelected(item) }>
        <DropdownItemText>{ item }</DropdownItemText>
      </DropdownItem>
    )
  }

  return (
    <DropdownContainer>
      <DropdownSelectedItemContainer>
        { props.placeholder || selectedItem }
        <DropdownItemsContainer>{ itemComponents }</DropdownItemsContainer>
      </DropdownSelectedItemContainer>
    </DropdownContainer>
  )

});

const DropdownContainer = styled.div`
  display: flex;
  height: 39.5px;
`;

const DropdownSelectedItemContainer = styled.button`
  border-radius: 10px;
  background-color: transparent;
  font-family: ${ props => props.theme.body_font };
  font-size: 16px;
  color: ${ props => props.theme.text_primary };
  padding: 10px;
  border: none;
  outline: ${ props => props.theme.neu ? "none" : `2px solid ${ props.theme.text_primary }` };
  box-shadow: ${ props => props.theme.neu ? `4px 4px 8px ${ props.theme.neu_theme.dark_shadow }, -4px -4px 8px ${ props.theme.neu_theme.light_shadow1 }` : "none" };
  
  &:hover {
    cursor: pointer;
  }
  
`;

const DropdownItemsContainer = styled.div`
  position: absolute;
  display: none;
  background-color: ${ props => props.theme.on_background };
  z-index: 100;
  box-shadow: ${ props => `0 0 10px 1px ${ props.theme.shadow }` };
  
  ${ DropdownSelectedItemContainer }:focus-within & {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 20px 0 0 -50px;
  }
`;

const DropdownItem = styled.div`
  padding: 10px;
`;

const DropdownItemText = styled.text`
  font-family: ${ props => props.theme.body_font };
  font-size: 16px;
  color: ${ props => props.theme.text_primary };
  display: inline-block;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${ props => props.theme.text_secondary };
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover {
    color: ${ props => props.theme.text_secondary };
    cursor: pointer;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &:hover {
    cursor: pointer;
  }
`;