import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "./res/fonts/AppleTea.ttf";
import "./res/fonts/Neonneon.otf"

import { NavBar } from "./components/NavBar";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Projects } from "./pages/Projects";
import { Contact } from "./pages/Contact";
import { Experience } from "./pages/Experience";
import { AlertList } from "./components/AlertList";
import { getTextWidth, url } from "./utils/Utils";
import { ProjectPage } from "./pages/ProjectPage";
import styled, { ThemeProvider } from "styled-components";
import { THEME } from "./utils/Theme";
import { Footer } from "./components/Footer";
import { NotFound } from "./pages/NotFound";
import { CookiesProvider } from "react-cookie";
import { ScrollToTop } from "./components/ScrollToTop";
import { Data } from "./data/Data";
import axios from 'axios';

export const App = () => {

  document.title = "Welcome!"


  const [ gone, setGone ] = useState(false);
  const [ animate, setAnimate ] = useState(false);

  useEffect(() => {
    if (animate) {
      setTimeout(() => {
        setGone(true);
        setAnimate(false);
      }, 3450);
    } else {
      setAnimate(true);
    }
  }, [ animate ]);

  const textSpans = [];
  let i = 0;
  for (let l of "WELCOME".split("")) {
    textSpans.push(
      <HomeScreenText animate={ animate } delay={ i } width={ getTextWidth(l, "min(13vw, 120px) AppleTea") }
                      key={ i } theme={ THEME.main }>{ l }</HomeScreenText>
    );
    i++;
  }

  const [ loaded, setLoaded ] = useState(false);
  const [ sent, setSent ] = useState(false);
  const [ projectsList, setProjectsList ] = useState([]);


  if (!sent) {
    setSent(true);

    const one = `${ url }/get-projects/`;
    const two = `${ url }/get-education/`;
    const three = `${ url }/get-experiences/`;
    const four = `${ url }/get-skills/`;
    const five = `${ url }/get-achievements/`;
    const six = `${ url }/get-projects-list/`;
    const seven = `${ url }/get-featured/`;

    const requestOne = axios.get(one);
    const requestTwo = axios.get(two);
    const requestThree = axios.get(three);
    const requestFour = axios.get(four);
    const requestFive = axios.get(five);
    const requestSix = axios.get(six);
    const requestSeven = axios.get(seven);

    axios.all([ requestOne, requestTwo, requestThree, requestFour, requestFive, requestSix, requestSeven ]).then(axios.spread((...responses) => {
      Data.ProjectDetails = responses[0].data;
      Data.Education = responses[1].data;
      Data.Experiences = responses[2].data;
      Data.Skills = responses[3].data;
      Data.Achievements = responses[4].data;
      Data.FeaturedProjects = JSON.parse(responses[6].data[0][1]);
      Data.FeaturedAchievements = JSON.parse(responses[6].data[1][1]);
      setProjectsList(responses[5].data);
      setLoaded(true);
    }));
  }


  const [ dimensions, setDimensions ] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const navbar = useRef(null);

  const alertsRef = useRef(null);


  useEffect(() => {
    function resize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", resize);
  });

  if (loaded && gone) {

    const pages = [];

    for (let project in projectsList) {
      const url = projectsList[project][0].toLowerCase().replaceAll(" ", "-");
      Data.ProjectDetails[project].push(url);
      pages.push(
        <Route path={ `/projects/${ url }/` } key={ url }
               element={ <ProjectPage navbar={ navbar } data={ Data.ProjectDetails[project] }/> }/>
      );
    }


    return (
      <ThemeProvider theme={ THEME.main }>
        <BrowserRouter>
          <ScrollToTop/>
          <NavBar ref={ navbar }/>
          <AlertList ref={ alertsRef }/>
          <Routes>
            <Route exact path="/" element={ <Home alertsRef={ alertsRef } navbar={ navbar }/> }/>
            <Route path="/home/" element={ <Navigate to="/"/> }/>
            <Route path="/about/" element={ <About alertsRef={ alertsRef } navbar={ navbar }/> }/>
            <Route path="/projects/" element={ <Projects alertsRef={ alertsRef } navbar={ navbar }/> }/>
            <Route path="/contact/" element={ <Contact alertsRef={ alertsRef } navbar={ navbar }/> }/>
            <Route path="/experience/" element={ <Experience alertsRef={ alertsRef } navbar={ navbar }/> }/>
            { pages }
            <Route path="*" element={ <NotFound/> }/>
          </Routes>
          <Footer/>
        </BrowserRouter>
      </ThemeProvider>
    );
  } else {
    return (
      <HomeScreenBack theme={ THEME.main }>
        <HomeScreen animate={ animate } gone={ gone }>
          <HomeScreenTextContainer animate={ animate }>{ textSpans }</HomeScreenTextContainer>
        </HomeScreen>
      </HomeScreenBack>
    );
  }
}


const HomeScreen = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 1000;
  perspective: 1000px;
  align-items: center;
  justify-content: center;
  animation: ${ props => props.animate ? "disappear 1s linear 2.5s" : "none" };
  display: ${ props => props.gone ? "none" : "flex" };


  @keyframes disappear {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;


const HomeScreenBack = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${ props => props.theme.background };
`;

const HomeScreenTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${ props => props.animate ? "10px" : "200px" };
  transition: 2s linear;
`;

const HomeScreenText = styled.span`
  display: inline-block;
  font-family: AppleTea;
  font-size: min(13vw, 120px);
  color: ${ props => props.theme.primary };
  animation: ${ props => props.animate ? `animate-in 1.5s linear calc(${ props.delay } * 0ms)` : "none" };
  opacity: 1;
  width: ${ props => `${ props.width }px` };
  text-shadow: 6px 6px 8px #646464, -6px -6px 8px #101010;

  @keyframes animate-in {
    0% {
      transform: rotateY(90deg);
      opacity: 0.2;
    }
    100% {
      transform: rotateY(0deg);
      opacity: 1;
    }
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <App/>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();