const React = require('react');
const { Data } = require("../data/Data");
const { useLocation } = require("react-router-dom");
const toSkewerCase = (str) =>
  str && str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.toLowerCase()).join('-');

const getTextContext = (font) => {
  const canvas = document.createElement("canvas");
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  const context = canvas.getContext("2d");
  context.font = font;
  return context;
}

export const getTextWidth = (text, font) => {
  const metrics = getTextContext(font).measureText(text);
  return metrics.width;
}

export const getTextHeight = (text, font) => {
  const metrics = getTextContext(font).measureText(text);
  return (metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent);
}

export const getTypes = () => {
  let types = [];

  for (let project of Data.ProjectDetails) {
    types = types.concat(JSON.parse(project[4]));
    types = unique(types);
  }

  return types;
}

export const unique = (arr) => {
  const a = arr.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j])
        a.splice(j--, 1);
    }
  }
  return a;
};

export const asyncTimeout = (time) => {
  return new Promise(resolve => setTimeout(resolve, time));
};

export const useLocationChange = (navbar) => {
  const location = useLocation()
  React.useEffect(() => {
    navbar.current.close()
  }, [ navbar, location ])
}

export const url = "https://website-data.josherlo.com";