import React, { useRef } from "react";
import styled from "styled-components";
import { ExpandableListTile } from "../components/ExpandableListTile";
import { Data } from "../data/Data";
import { useLocationChange } from "../utils/Utils";

export const Experience = (props) => {

  document.title = "My experiences"

  useLocationChange(props.navbar);

  const experienceTiles = [];
  const achievementTiles = [];
  const experienceTilesRef = useRef({});
  const achievementTilesRef = useRef({});

  const closeAllExcept = (i, a) => {
    for (let tile in (a ? experienceTilesRef.current : achievementTilesRef.current)) {
      if (tile != i) {
        a ? experienceTilesRef.current[tile].close() : achievementTilesRef.current[tile].close();
      }
    }
  };

  for (let experience in Data.Experiences) {
    const copy = experience;
    experienceTiles.push(
      <ExpandableListTile onClick={ () => closeAllExcept(copy, true) }
                          width={ window.innerWidth <= 620 ? window.innerWidth - 40 : window.innerWidth - 120 }
                          key={ copy }
                          data={ Data.Experiences[copy] } ref={ ref => experienceTilesRef.current[copy] = ref }
                          yearWidth={ window.innerWidth <= 620 ? 40 : 55 }/>
    );
  }

  for (let acheivement in Data.Achievements) {
    const copy = acheivement;
    achievementTiles.push(
      <ExpandableListTile onClick={ () => closeAllExcept(copy, false) }
                          width={ window.innerWidth <= 620 ? window.innerWidth - 40 : window.innerWidth - 120 }
                          key={ copy }
                          data={ Data.Achievements[copy] } ref={ ref => achievementTilesRef.current[copy] = ref }
                          yearWidth={ window.innerWidth <= 620 ? 40 : 55 }/>
    );
  }

  return (
    <ExperienceRoot>
      <ExperienceTitle>EXPERIENCE</ExperienceTitle>
      <ExperienceList>{ experienceTiles }</ExperienceList>
      <ExperienceTitle>ACHIEVEMENTS</ExperienceTitle>
      <ExperienceList>{ achievementTiles }</ExperienceList>
      <div style={ { height: "80px" } }/>
    </ExperienceRoot>
  )
}

const ExperienceRoot = styled.div`
  padding: 80px 0 0 0;
  background-color: ${ props => props.theme.background };
  display: flex;
  flex-direction: column;
  
  @media(max-width: 620px) {
    align-items: center;
    justify-content: center;
  }
`;

const ExperienceTitle = styled.text`
  font: ${ props => `56px ${ props.theme.title_font }` };
  color: ${ props => `${ props.theme.primary }` };
  margin: 40px 0 0 0;
  
  @media(max-width: 620px) {
    font-size: 40px;
  }
`;

const ExperienceList = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - 120px);
  margin: 20px 0 0 0;
`;
