import React, { useRef, useState } from "react";
import styled from "styled-components";
import { getTextWidth } from "../utils/Utils";
import "../styles/a.css";

const emoji = require("node-emoji");

export const TabView = (props) => {

  const [ selected, setSelected ] = useState(0);
  const headers = useRef({});

  const tabs = [];
  const contents = [];
  const widthsBold = [];
  const widths = [];

  for (let i in Object.entries(props.tabs)) {
    widthsBold.push(getTextWidth(Object.entries(props.tabs)[i][0], `bold ${ window.innerWidth <= 620 ? 14 : 18 }px Electrolux`));
    widths.push(getTextWidth(Object.entries(props.tabs)[i][0], `${ window.innerWidth <= 620 ? 14 : 18 }px Electrolux`));
    const copy = i;
    tabs.push(
      <TabViewHeaderTab onClick={ () => setSelected(copy) } key={ i } selected={ selected == i }
                        ref={ ref => headers.current[copy] = ref }>{ Object.entries(props.tabs)[i][0] }</TabViewHeaderTab>
    );
    contents.push(
      <TabViewContent key={ i } selected={ selected == i }
                      dangerouslySetInnerHTML={ { __html: emoji.emojify(Object.entries(props.tabs)[i][1]) } }/>
    );
    i++;
  }


  return (
    <TabViewRoot>
      <div style={ { height: "20px" } }/>
      <TabViewHeader>{ tabs }</TabViewHeader>
      <TabViewHeaderBar
        offset={ widths.slice(0, selected).reduce((partialSum, a) => partialSum + a, 0) + selected * 20 }
        width={ widthsBold[selected] }/>
      <TabViewContentContainer>{ contents }</TabViewContentContainer>
    </TabViewRoot>
  )
}

const TabViewRoot = styled.div`
  z-index: 1;
`;

const TabViewHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const TabViewHeaderTab = styled.text`
  font-family: ${ props => props.theme.body_font };
  font-size: 18px;
  font-weight: ${ props => props.selected ? "bold" : "normal" };
  color: ${ props => props.selected ? props.theme.primary : props.theme.text_primary };

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 620px) {
    font-size: 14px;
  }
`;

const TabViewHeaderBar = styled.div`
  background-color: ${ props => props.theme.primary };
  margin-left: ${ props => `${ props.offset }px` };
  width: ${ props => `${ props.width }px` };
  height: 2px;
  transition: 250ms ease-in-out;
`;

const TabViewContentContainer = styled.div`
  margin: 20px 0 0 0;
`;

const TabViewContent = styled.div`
  display: ${ props => props.selected ? "block" : "none" };
  color: ${ props => props.theme.text_secondary };
  line-height: 24px;

  @media (max-width: 620px) {
    font-size: 14px;
  }
`;