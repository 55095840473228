import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import styled from "styled-components";
import { ChevronLeftRounded } from "@mui/icons-material";
import { TabView } from "./TabView";

const emoji = require("node-emoji");

export const ExpandableListTile = forwardRef((props, ref) => {

  const [ expanded, setExpanded ] = useState(false);
  const [ opacity, setOpacity ] = useState(0);
  const [ transition, setTransition ] = useState(0);
  const [ height, setHeight ] = useState(-1);
  const conRef = useRef(null);

  const openTabView = () => {
    const copyHeight = conRef.current.clientHeight + 40;
    setHeight(0);
    setOpacity(1);
    setTransition(250);
    setTimeout(() => {
      setHeight(copyHeight);
    }, 50);
  };

  const closeTabView = () => {
    setHeight(0);
    setTimeout(() => {
      setOpacity(0);
      setTransition(0);
      setHeight(-1);
    }, 250);
  }

  useImperativeHandle(ref, () => ({
    close: () => {
      closeTabView();
      setTimeout(() => {
        setExpanded(false);
      }, 300);
    },
    open: () => {
      setExpanded(true);
      setTimeout(() => {
        openTabView();
      }, 50);
    }
  }))

  return (
    <ExpandableListTileContainer width={ props.width }>
      <ExpandableListTileHeader expanded={ expanded } width={ props.width } onClick={ () => {
        props.onClick();
        if (!expanded) {
          setExpanded(true);
          setTimeout(() => {
            openTabView();
          }, 50);
        } else {
          closeTabView();
          setTimeout(() => {
            setExpanded(false);
          }, 300);
        }
      } }>
        <ExpandableListTileHeaderYear width={ props.yearWidth }>{ props.data[2] }</ExpandableListTileHeaderYear>
        <ExpandableListTilePlacingEmoji>{ emoji.emojify(props.data[3]) }</ExpandableListTilePlacingEmoji>
        <ExpandableListTileHeaderTitle>{ props.data[1] }</ExpandableListTileHeaderTitle>
        <ExpandableListTileHeaderIcon expanded={ expanded }><ChevronLeftRounded
          fontSize="large"/></ExpandableListTileHeaderIcon>
      </ExpandableListTileHeader>
      <ExpandableListTileExpandedContainer ref={ conRef } position={ opacity == 1 ? "relative" : "absolute" }
                                           transition={ transition } height={ height } opacity={ opacity }
                                           expanded={ expanded }>
        <TabView position={ opacity == 1 ? "relative" : "absolute" } tabs={ JSON.parse(props.data[4]) }/>
      </ExpandableListTileExpandedContainer>
    </ExpandableListTileContainer>
  );
});

const ExpandableListTileContainer = styled.div`
  width: ${ props => `${ props.width }px` };
`;

const ExpandableListTileHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${ props => props.expanded ? "5px 5px 0 0" : "5px" };
  background-color: ${ props => props.theme.on_background };
  box-shadow: ${ props => `0 0 10px 1px ${ props.theme.shadow }` };
  z-index: 1;
  border-bottom: ${ props => props.expanded ? `2px solid ${ props.theme.outline }` : "none" };
  
  &:hover {
    cursor: pointer;
  }
`;

const ExpandableListTileHeaderYear = styled.text`
  font-family: ${ props => props.theme.body_font };
  font-size: 20px;
  font-weight: bold;
  color: ${ props => props.theme.text_secondary };
  width: ${ props => `${ props.width }px`};
  padding: 10px 0 10px 20px;

  @media(max-width: 620px) {
    font-size: 14px;
  }
`;

const ExpandableListTileHeaderTitle = styled.text`
  font-family: ${ props => props.theme.body_font };
  font-size: 20px;
  color: ${ props => props.theme.text_primary };
  flex: 1;
  margin: 0 0 0 10px;
  
  @media(max-width: 620px) {
    font-size: 14px;
  }
`;

const ExpandableListTileHeaderIcon = styled.div`
  transform: ${ props => `rotate(${ props.expanded ? 90 : -90 }deg)` };
  color: ${ props => props.theme.text_secondary };
  transition: 250ms ease-in-out;
  width: 2.18rem;
  height: 2.18rem;
`;

const ExpandableListTileExpandedContainer = styled.div`
  display: ${ props => props.expanded ? "flex" : "none" };
  height: ${ props => `${ props.height }px` };
  transition: ${ props => `height ${ props.transition }ms ease-in-out` };
  overflow: hidden;
  opacity: ${ props => props.opacity };
  position: ${ props => props.position };
  flex-direction: column;
  padding: 0 20px 0 20px;
  border-radius: 0 0 5px 5px;
  background-color: ${ props => props.theme.on_background };
  z-index: 0;
`;

const ExpandableListTilePlacingEmoji = styled.text`
  @media(max-width: 620px) {
    font-size: 14px;
  }
`;