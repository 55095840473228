import React from "react";
import styled from "styled-components";
import { GitHub, OpenInNewRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { getTextWidth } from "../utils/Utils";

const icons = {
  "github": <GitHub/>,
  "site": <OpenInNewRounded/>,
};

const ProjectTile = (props) => {

  const chips = [];

  for (let tool of JSON.parse(props.data[4])) {
    chips.push(<ProjectTileChip onClick={ () => props.filter(tool) }>{ tool }</ProjectTileChip>);
  }

  const buttons = [];

  for (let [ site, link ] of Object.entries(JSON.parse(props.data[7]))) {
    buttons.push(
      <ProjectTileIconButton
        onClick={ () => window.open(link, "_blank") }
      >
        { icons[site] }
      </ProjectTileIconButton>
    )
  }

  let width = getTextWidth(props.data[0], "20px AppleTea");

  if (width > (window.innerWidth > 1100 ? 280 : 200)) {
    let string = "";
    const list = props.data[0].split(" ");
    string += list[0];
    let list1 = [];
    for (let i = 0; i < list.length - 1; i++) {
      if (getTextWidth((string + " " + list[i + 1]).trim(), "20px AppleTea") > (window.innerWidth > 1100 ? 280 : 200)) {
        list1.push(string.trim());
        string = "";
      }
      string += " " + list[i + 1];
    }
    list1.push(string.trim());
    width = Math.max(...list1.map(e => getTextWidth(e, "20px AppleTea")));
  }

  return (
    <ProjectTileContainer>
      <ProjectTileHeader>
        <ProjectTileIcon>{ props.icon }</ProjectTileIcon>
        <ProjectTileLinksBox>
          { buttons }
        </ProjectTileLinksBox>
      </ProjectTileHeader>
      <ProjectTileBody>
        <ProjectTileTitle width={ `${ width }px` } to={ props.home ? `/projects/${ props.data[9] }/` : props.data[9] }>{ props.data[0] }</ProjectTileTitle>
        <ProjectTileDescription>{ props.data[1] }</ProjectTileDescription>
      </ProjectTileBody>
      <ProjectTileChips>{ chips }</ProjectTileChips>
    </ProjectTileContainer>
  );
}

const ProjectTileContainer = styled.div`
  padding: 20px;
  border-radius: 5px;
  width: 280px;
  height: 180px;
  display: flex;
  flex-direction: column;
  box-shadow: ${ props => props.theme.neu ? `4px 4px 10px ${ props.theme.neu_theme.dark_shadow }, -4px -4px 10px ${ props.theme.neu_theme.light_shadow4 }` : `0px 0px 3px 1px ${ props.theme.shadow }` };
  background-color: ${ props => props.theme.neu ? props.theme.background : props.theme.on_background };
  transition: margin 250ms ease-out, box-shadow 250ms ease-out;

  &:hover {
    margin: -10px 0 0 0;
    box-shadow: ${ props => props.theme.neu ? `4px 4px 10px ${ props.theme.neu_theme.dark_shadow }, -4px -4px 10px ${ props.theme.neu_theme.light_shadow2 }` : `0px 0px 15px 5px ${ props.theme.shadow }` };
    transition: margin 250ms ease-out, box-shadow 250ms ease-out, background-color 250ms ease-out;
  }

  @media (max-width: 1100px) {
    width: 200px;
    height: 240px;
  }
  
  @media (max-width: 620px) {
    width: 320px;
    height: 200px;
  }

`;

const ProjectTileHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProjectTileIcon = styled.div`
  padding: 0 20px 0 0;
  flex: 1;
  
  &:first-child {
    color: ${ props => props.theme.text_primary };
    transition: color 250ms ease-out;
  }
  
  
  ${ ProjectTileContainer }:hover & :first-child {
    color: ${ props => props.theme.secondary };
    transition: color 250ms ease-out;
  }
`;

const ProjectTileLinksBox = styled.div`
  
`;

const ProjectTileBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 5px 0 0 0;
`;

const ProjectTileTitle = styled(Link)`
  display: inline-block;
  position: relative;
  font-family: ${ props => props.theme.title_font };
  font-size: 20px;
  margin: 5px 0 0 0;
  color: ${ props => props.theme.text_primary };
  transition: color 250ms ease-out;
  text-decoration: none;
  
  
  ${ ProjectTileContainer }:hover & {
    color: ${ props => props.theme.secondary };
    transition: color 250ms ease-out;
  }
  
  ${ ProjectTileContainer }:hover &:hover {
    cursor: pointer;
  }
  
  &:first-child {
    display: inline-block;
    position: relative;
  }
  
  &:first-child:after {
    content: "";
    position: absolute;
    width: ${ props => props.width };
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${ props => props.theme.secondary };
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:first-child:hover {
    color: ${ props => props.theme.secondary };
    cursor: pointer;
  }

  &:first-child:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
`;

const ProjectTileDescription = styled.text`
  font-family: ${ props => props.theme.body_font };
  font-size: 16px;
  color: ${ props => props.theme.text_secondary };
  padding: 10px 0 0 0;
  transition: color 250ms ease-out;

  ${ ProjectTileContainer }:hover & {
    color: ${ props => props.theme.secondary_text_primary };
  }

`;

const ProjectTileChips = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const ProjectTileChip = styled.text`
  color: ${ props => props.theme.text_disabled };
  padding: 0 10px 0 0;
  font-family: ${ props => props.theme.body_font };
  font-size: 14px;
  transition: color 250ms ease-out;

  ${ ProjectTileContainer }:hover & {
    color: ${ props => props.theme.secondary_text_secondary };
  }
  
  &:hover {
    cursor: pointer;
  }

`;

const ProjectTileIconButton = styled.button`
  background-color: transparent;
  color: ${ props => props.theme.text_primary };
  border: none;
  transition: color 250ms ease-out;

  ${ ProjectTileContainer }:hover & {
    color: ${ props => props.theme.secondary };
    transition: color 250ms ease-out;
  }

  ${ ProjectTileContainer }:hover &:hover {
    color: ${ props => props.theme.secondary_dark };
    cursor: pointer;
    transition: color 250ms ease-out;
  }
`;

export default ProjectTile;