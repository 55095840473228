import React from "react";
import styled from "styled-components";

export const Footer = (props) => {

  return (
    <FooterContainer>
      <FooterBar />
      <FooterText>© Josher Lo 2022 - Find me on GitHub <FooterLink href='https://github.com/JosherLo'>here</FooterLink>.</FooterText>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: ${ props => props.theme.background };
`;

const FooterBar = styled.div`
  width: calc(100vw - 80px);
  height: 2px;
  background-color: ${ props => props.theme.outline_dark };
`;

const FooterText = styled.text`
  font: 14px Electrolux;
  color: ${ props => props.theme.outline_dark }
`;

const FooterLink = styled.a`
  display: inline-block;
  position: relative;
  color: ${ props => props.theme.outline };
  text-decoration: none;
  
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${ props => props.theme.outline_dark };
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  &:hover {
    color: ${ props => props.theme.outline_dark };
    cursor: pointer;
  }
  
  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
`;