import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";

export const Alert = (props) => {
  const [ width, setWidth ] = useState(0);
  const [ gone, setGone ] = useState(false);
  const [ rot, setRot ] = useState(0);
  const [ duration, setDuration ] = useState(0.3);
  const [ height, setHeight ] = useState(0);

  const [ timeout1, setTimeout1 ] = useState(null);
  const [ timeout2, setTimeout2 ] = useState(null);

  const handleClose = () => {
    setRot(-30);
    setTimeout(() => {
      setDuration(0.6);
      setRot(90);
      setTimeout(() => {
        setGone(true);
        setTimeout(() => {
          props.remove(props.id);
          clearTimeout(timeout1);
          clearTimeout(timeout2);
        }, 600);
      }, 600);
    }, 300);
  };

  const ref = useRef(null);

  useEffect(() => {
    if (!gone && width === 0 && timeout1 === null && timeout2 === null) {
      const t1 = setTimeout(() => {
        setWidth(props.width);
        const t2 = setTimeout(() => {
          handleClose();
        }, props.time);
        setTimeout2(t2);
      }, 100);
      setTimeout1(t1);
    }
  }, [ gone, handleClose, width, timeout1, timeout2, props ]);

  useLayoutEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  }, []);

  return (
    <AlertRoot ref={ ref }>
      <AlertContainer gone={ gone } height={ height } { ...props }>
        <Alert3DContainer rot={ rot } duration={ duration } width={ props.width }>
          <AlertCloseButton onClick={ handleClose } { ...props }>&times;</AlertCloseButton>
          <AlertTextContainer { ...props }>
            <AlertTitle>{ props.title }</AlertTitle>
            <AlertText>{ props.text }</AlertText>
          </AlertTextContainer>
          <AlertTimerContainer { ...props }>
            <AlertTimerFilled type={ props.type } time={ props.time } width={ width }/>
          </AlertTimerContainer>
        </Alert3DContainer>
      </AlertContainer>
    </AlertRoot>
  );
}

const AlertRoot = styled.div`
  
`;

const AlertContainer = styled.div`
  margin-bottom: 20px;
  margin-top: ${ props => props.gone ? `${ -props.height }px` : `${ props.marginTop }px` };
  width: ${ props => `${ props.width }px` };
  perspective: 1000px;
  opacity: ${ props => props.gone ? 0 : 1 };
  display: flex;
  transition: ${ props => `margin-top 0.6s ease-in-out` };
`;

const Alert3DContainer = styled.div`
  width: ${ props => `${ props.width }px` };
  background-color: ${ props => props.theme.on_background };
  transform: ${ props => `rotateX(${ props.rot }deg)` };
  transform-style: preserve-3d;
  transition: ${ props => `transform ${ props.duration }s ease-out` };
  box-shadow: ${ props => `0 4px 10px 1px ${ props.theme.shadow }` };
  border-radius: 10px;
`;

const AlertTextContainer = styled.div`
  width: ${ props => `${ props.width - 35 }px` };
  margin: 10px 0 10px 10px;
  display: flex;
  flex-direction: column;
`;

const AlertTitle = styled.text`
  font-family: ${ props => props.theme.title_font };
  font-size: 20px;
  color: ${ props => props.theme.primary };
`;

const AlertText = styled.text`
  font-family: ${ props => props.theme.body_font };
  font-size: 18px;
  color: ${ props => props.theme.text_primary };
`;

const AlertCloseButton = styled.span`
  color: black;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.1s;
  margin: 2px 5px 0 0;
  
  &:hover {
    color: black;
  }
`;

const AlertTimerContainer = styled.div`
  height: 20px;
  width: ${ props => `${ props.width }px` };
  background-color: ${ props => props.theme.on_background };
  border-radius: 0 0 10px 10px;
  overflow: hidden;
`;

const AlertTimerFilled = styled.div`
  width: ${ props => `${ props.width }px` };
  height: 20px;
  background-color: ${ props => props.type === 0 ? props.theme.success : (props.type === 1 ? props.theme.error_dark : props.theme.warning) };
  transition: ${ props => `${ props.time }ms linear` };
`;