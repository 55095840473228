import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

export const TextArea = React.forwardRef((props, ref) => {

  const [ empty, setEmpty ] = useState(true);

  useEffect(() => {
  }, [ empty ]);

  const onChange = (e) => {
    setEmpty(e.target.value.length === 0);
  }

  const onClick = () => {
    ref.current.focus();
  }

  return (
    <TextAreaContainer width={ props.width || 400 } height={ props.height || 100 } { ...props }>
      <TextAreaInput onChange={ onChange } ref={ ref } width={ props.width || 400 } height={ props.height || 100 }/>
      <TextAreaLabel empty={ empty } onClick={ onClick }>{ props.label }</TextAreaLabel>
    </TextAreaContainer>
  );
});

const TextAreaContainer = styled.div`
  display: flex;
  width: ${ (props) => `${ props.width - 10 }px` };
  height: ${ (props) => `${ props.height - 10 }px` };
  //flex-wrap: wrap;
  border-radius: 10px;
  border: none;
  margin: 10px;
  grid-row-start: ${ props => props.rowStart || 0 };
  grid-row-end: ${ props => props.rowEnd || 0 };
  grid-column-start: ${ props => props.colStart || 0 };
  grid-column-end: ${ props => props.colEnd || 0 };
`;

const TextAreaInput = styled.textarea`
  font-size: 16px;
  font-family: ${ props => props.theme.body_font };
  width: ${ (props) => `${ props.width - 10 }px` };
  height: ${ (props) => `${ props.height - 10 }px` };
  outline: ${ props => props.neu ? "none" : `2px solid ${ props.theme.outline }` };
  border: none;
  box-shadow: ${ props => props.theme.neu ? `4px 4px 8px ${ props.theme.neu_theme.dark_shadow }, -4px -4px 8px ${ props.theme.neu_theme.light_shadow4 }` : "none" };
  background-color: transparent;
  border-radius: 10px;
  transition: 50ms ease-out;
  resize: none;
  padding: 15px 20px 0 10px;
  color: ${ props => props.theme.outline };

  &:focus {
    outline: ${ props => props.neu ? "none" : `2px solid ${ props.theme.primary }` };
    box-shadow: ${ props => props.theme.neu ? `2px 2px 8px ${ props.theme.neu_theme.dark_shadow }, -2px -2px 8px ${ props.theme.neu_theme.light_shadow2 }` : "none" };
    color: ${ props => props.theme.text_primary };
  }
`;

const TextAreaLabel = styled.text`
  position: absolute;
  font-size: 14px;
  font-family: ${ props => props.theme.title_font };
  margin: -8px 0 0 14px;
  transition: color 50ms ease-out, margin 150ms ease-out, font-size 150ms ease-out, background-color 100ms ease-out;
  background-color: ${ props => props.theme.background };
  padding: 0 5px 0 5px;
  color: ${ props => props.theme.outline };

  ${ props => props.empty && css`
    font-size: 16px;
    margin: 13px 0 0 10px;
    background-color: transparent;
    color: ${ props => props.theme.text_hint };
  ` };
  
  ${ TextAreaInput }:focus ~ & {
    font-size: 14px;
    margin: -8px 0 0 14px;
    color: ${ props => props.theme.primary };
    background-color: ${ props => props.theme.background };
    transition: color 50ms ease-out, margin 150ms ease-out, font-size 150ms ease-out, background-color 100ms ease-out;
  }
`