import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { LanguageBadge } from "../components/LanguageBadge";
import "../styles/a.css";
import { ExpandableListTile } from "../components/ExpandableListTile";
import { Data } from "../data/Data";
import { DividerText } from "../components/DividerText";
import { Button } from "../components/Button";
import { Link } from "react-router-dom";
import { useLocationChange } from "../utils/Utils";

const emoji = require("node-emoji");

const aboutText = "<p>Hello :wave:! My name is Josher Lo, and I am a student from Singapore :flag-sg: studying in <a href='https://www.nushigh.edu.sg/'>NUS High</a>! I have a lot of interest in biology :dna:, chemistry :test_tube:, and programming :computer:! My interest in all these fields developed during my time in school.</p>" +
  "<p>During my first year there when I was 13, I enrolled in a <a href='https://www.udemy.com/course/the-complete-android-oreo-developer-course/'>Udemy course</a> teaching me Android. This was my first every exposure to the wonderful world of programming! Since then, I started self learning programming, and started to venture into the other fields of programming.</p>" +
  "<p>And then, I started my first computer science module in school. In my honest opinion even though the modules taught me a lot, I still felt that self learning programming was much more fun and enjoyable. If I ran into any trouble, YouTube tutorials and StackOverflow also came to my rescue. With only 3 years of experience in programming, I feel proud that I have learned and developed so much!</p>" +
  "<p>Besides the strong passion for programming, I also love reading widely about developments in biology or chemistry, and constantly challenge myself with difficult yet interesting questions.</p>"

const lifeText = "<p>I was born and grew up in Singapore :flag-sg:, but lived in Canada :flag-ca: for a year in 2014 when my mother moved there for her work.</p>" +
  "<p>I really enjoyed living in Canada, and made friends there, and occasionally go back to visit them.</p>" +
  "<p>I know how to play the flute and piano :musical_keyboard:, and I love listening to classical, musical and movie music!</p>";

export const About = (props) => {

  useLocationChange(props.navbar);

  document.title = "About me"

  const educationTiles = [];
  const educationTilesRef = useRef({});

  const closeAllExcept = (i) => {
    for (let tile in educationTilesRef.current) {
      if (tile != i) {
        educationTilesRef.current[tile].close();
      }
    }
  };

  for (let education in Data.Education) {
    const copy = education;
    educationTiles.push(
      <ExpandableListTile onClick={ () => closeAllExcept(copy) }
                          width={ window.innerWidth <= 620 ? window.innerWidth - 40 : window.innerWidth - 120 }
                          key={ copy }
                          data={ Data.Education[copy] } ref={ ref => educationTilesRef.current[copy] = ref }
                          yearWidth={ window.innerWidth <= 620 ? 85 : 120 }/>
    );
  }

  const tools = useRef(null);
  const list = useRef(null);
  const [ temp, setTemp ] = useState(0);

  const badges = [];
  for (let skill in Data.Skills) {
    badges.push(
      <LanguageBadge item={ skill } onClick={ (e) => {
      } } text={ Data.Skills[skill][0] } icon={ Data.Skills[skill][1] }/>
    )
  }

  useEffect(() => {
    if (temp == 0) {
      setTimeout(() => {
        setTemp(temp + 1);
      }, 100);
    }
  }, [ temp ]);

  return (
    <AboutRoot>
      <AboutTitle>{ emoji.emojify("Hi! :wave:") }</AboutTitle>
      <AboutText dangerouslySetInnerHTML={ { __html: emoji.emojify(aboutText) } }/>
      <AboutSubHeader>{ emoji.emojify("TOOLS USED :wrench:") }</AboutSubHeader>
      <AboutBadgeContainer ref={ tools }>{ badges }</AboutBadgeContainer>
      <AboutSubHeader>{ emoji.emojify("MY LIFE :smile:") }</AboutSubHeader>
      <AboutText dangerouslySetInnerHTML={ { __html: emoji.emojify(lifeText) } }/>
      <AboutSubHeader>{ emoji.emojify("EDUCATION :scroll:") }</AboutSubHeader>
      <AboutEducationList ref={ list }>{ educationTiles }</AboutEducationList>
      <DividerText text="MORE" vertical={ false } length={ window.innerWidth - 120 }/>
      <AboutButtonsContainer>
        <AboutLink to="../projects">
          <Button padding={ 2 } fontSize={ window.innerWidth <= 620 ? 14 : 26 } text="PROJECTS" onClick={ () => {
          } } type="primary"/>
        </AboutLink>
        <AboutLink to="../experience">
          <Button padding={ 2 } fontSize={ window.innerWidth <= 620 ? 14 : 26 } text="EXPERIENCE" onClick={ () => {
          } } type="primary"/>
        </AboutLink>
        <AboutLink to="../contact">
          <Button padding={ 2 } fontSize={ window.innerWidth <= 620 ? 14 : 26 } text="CONTACT" onClick={ () => {
          } } type="primary"/>
        </AboutLink>
      </AboutButtonsContainer>
      <AboutBackground1 offsetTop={ tools.current == null ? 0 : tools.current.offsetTop - 60 }
                        height={ tools.current == null ? 0 : tools.current.clientHeight + 100 }
                        width={ tools.current == null ? 0 : window.innerWidth }/>
      <AboutBackground2 offsetTop={ list.current == null ? 0 : list.current.offsetTop - 50 }
                        height={ list.current == null ? 0 : list.current.clientHeight + 100 }
                        width={ list.current == null ? 0 : window.innerWidth }/>
    </AboutRoot>
  );
}

const AboutRoot = styled.div`
  padding: 80px 0 0 0;
  background-color: ${ props => props.theme.background };
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
`;

const AboutTitle = styled.text`
  font-family: ${ props => props.theme.title_font };
  font-size: 64px;
  color: ${ props => props.theme.primary };
  align-self: flex-start;
  justify-self: flex-start;

  padding: 0 60px;
  @media (max-width: 620px) {
    padding: 0 30px;
    font-size: 45px;
  }
`;

const AboutSubHeader = styled.text`
  font-family: ${ props => props.theme.title_font };
  font-size: 44px;
  color: ${ props => props.theme.primary };
  align-self: flex-start;
  justify-self: flex-start;
  z-index: 2;
  padding: 0 60px;
  @media (max-width: 620px) {
    padding: 0 30px;
    font-size: 30px;
  }
`;

const AboutBadgeContainer = styled.div`
  align-self: center;
  justify-self: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 40px;
  z-index: 2;
  padding: 0 60px;
  margin: 40px 0 60px 0;
  @media(max-width: 620px) {
    padding: 0 20px;
  }
`;

const AboutText = styled.text`
  font-family: ${ props => props.theme.body_font };
  font-size: 22px;
  color: ${ props => props.theme.text_primary };
  align-self: flex-start;
  justify-self: flex-start;
  line-height: 30px;
  padding: 0 60px;
  @media (max-width: 620px) {
    padding: 0 30px;
    font-size: 16px;
  }
`;

const AboutBackground1 = styled.div`
  position: absolute;
  transform: rotate(-2deg);
  background-color: ${ props => props.theme.background_secondary };
  top: ${ props => `${ props.offsetTop }px` };
  height: ${ props => `calc(${ props.height }px * 0.99939082701)` };
  width: ${ props => `calc(${ props.width }px / 0.99939082701 + ${ props.height }px * 0.0348994967)` };
  left: ${ props => `calc(-${ props.height }px * 0.0348994967 / 2 - 0.5px)` };
`;

const AboutBackground2 = styled.div`
  position: absolute;
  transform: rotate(2deg);
  background-color: ${ props => props.theme.background_secondary };
  top: ${ props => `${ props.offsetTop }px` };
  height: ${ props => `calc(${ props.height }px * 0.99619469809)` };
  width: ${ props => `calc(${ props.width }px / 0.99619469809 + ${ props.height }px * 0.08715574274)` };
  left: ${ props => `calc(-${ props.height }px * 0.08715574274 / 2 - 2.4px)` };
`;

const AboutEducationList = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - 120px);
  padding: 0 60px;
  z-index: 2;
  margin: 40px 0 60px 0;
  
  @media(max-width: 620px) {
    padding: 0 20px;
    width: calc(100vw - 40px);
  }
`;

const AboutButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 0 60px 60px 60px;
  z-index: 2;
  @media(max-width: 620px) {
    padding: 0 20px 60px 20px;
    gap: 10px;
  }
`;

const AboutLink = styled(Link)`
  &::after {
    background-color: transparent !important;
  }
`;
