import React, { forwardRef, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import { Alert } from "./Alert";

export const AlertList = forwardRef((props, ref) => {

  const [ alerts, setAlerts ] = useState([]);

  useImperativeHandle(ref, () => ({
    AddAlert: (details) => {
      const id = Math.floor(Math.random() * 900000) + 100000;
      const alert = {
        id: id,
        title: details.title,
        text: details.text,
        type: details.type,
      };
      setAlerts(old => [ ...old, alert ]);
      return id;
    },
    removeAlert: (id) => {
      setAlerts(old => [ ...(old.filter(a => a.id !== id)) ]);
    },
  }));

  const alertComponents = alerts.map((a, i) => (
    <Alert title={ a.title } text={ a.text } type={ a.type } width={ window.innerWidth > 700 ? 300 : window.innerWidth }
           time={ 10000 } id={ a.id } key={ a.id } remove={ ref.current.removeAlert }/>
  ));

  return (
    <AlertListContainer>{ alertComponents }</AlertListContainer>
  );
});

const AlertListContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 100000;
  width: 300px;
  right: 0;
  margin: 80px 20px 0 0;
  
  @media(max-width: 700px) {
    width: 100%;
    margin: 0;
  }
`;